<template lang="pug">
  v-app
    .d-flex.h-100(:class="mobileView ? 'flex-column-reverse' : 'flex-row'")
      v-carousel(
        cycle
        :height="mobileView? 'auto' : '500'"
        hide-delimiters
      )
        v-carousel-item(
          v-for="(i, index) in imageList"
          :key="index"
          :src="i"
          contain
        )
      img.image(src="@/assets/images/home/home_person1.png")
</template>

<script>
import config from "@/config";
import errorTags from "@/errorTags";

export default {
  computed: {
    imageList() {
      return [
        require(`@/assets/images/home/home_slider_1_${this.$i18n.locale}.png`),
        require(`@/assets/images/home/home_slider_2_${this.$i18n.locale}.png`),
        // require(`@/assets/images/home/home_slider_3_${this.$i18n.locale}.png`),
        require(`@/assets/images/home/home_slider_4_${this.$i18n.locale}.png`)
      ];
    },
    mobileView() {
      return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs;
    }
  },
  data() {
    return {
      loading: false,
      feedback: {
        success: false,
        status: errorTags.request.failed
      }
    };
  },
  methods: {}
};
</script>

<style lang="sass" scoped>
@import '~vuetify/src/styles/styles.sass'

@media #{map-get($display-breakpoints, 'md-and-up')}
  .v-application
    height: 82vh !important
    overflow: hidden !important

.v-carousel
  margin: auto
  width: 60%
  @media #{map-get($display-breakpoints, 'sm-and-down')}
    width: 95%
    .v-window-item
      width: 92%
      margin: auto
    // .v-window__prev, .v-window__next
    //   margin: 0 !important

.image
  margin: auto
  width: 37%
  @media #{map-get($display-breakpoints, 'sm-and-down')}
    width: 80%
</style>
