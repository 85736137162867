import { render, staticRenderFns } from "./HomePage.vue?vue&type=template&id=7fa401b8&scoped=true&lang=pug&"
import script from "./HomePage.vue?vue&type=script&lang=js&"
export * from "./HomePage.vue?vue&type=script&lang=js&"
import style0 from "./HomePage.vue?vue&type=style&index=0&id=7fa401b8&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7fa401b8",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VApp } from 'vuetify/lib/components/VApp';
import { VCarousel } from 'vuetify/lib/components/VCarousel';
import { VCarouselItem } from 'vuetify/lib/components/VCarousel';
installComponents(component, {VApp,VCarousel,VCarouselItem})
